import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "welcome"
    }}>{`Welcome!`}</h1>
    <p>{`If you are a `}<strong parentName="p">{`developer`}</strong>{`, this is the right place for you.`}<br parentName="p"></br>{`
`}{`Here you can browse the latest guides, conventions and tools used at `}<a parentName="p" {...{
        "href": "https://wild.as"
      }}>{`wild`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">
        <Link to="/boilerplates/getting-started" mdxType="Link">Our boilerplates</Link>
      </li>
      <li parentName="ul">
        <Link to="/code-linting/getting-started" mdxType="Link">Our code linting configurations</Link>
      </li>
      <li parentName="ul">
        <Link to="/github-actions/getting-started" mdxType="Link">Our github actions</Link>
      </li>
      <li parentName="ul">
        <Link to="/private-registry" mdxType="Link">How to access our private registry</Link>
      </li>
      <li parentName="ul">
        <Link to="/styled/getting-started" mdxType="Link">Our internal design system</Link>
      </li>
    </ul>
    <p>{`If you are already in our `}<a parentName="p" {...{
        "href": "https://github.com/madebywild"
      }}>{`Github organization`}</a>{`, feel free to leave us your `}<a parentName="p" {...{
        "href": "https://github.com/madebywild/docs.wild.plus/issues/new"
      }}>{`feedback`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      